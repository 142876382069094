.div {
    padding: 1rem;
    background-color: #EBF1F4;
    display: flex;
    align-items: center;
    gap: 1rem;
}


.img:hover {
    cursor: pointer;
}

.headertext {
    text-align: center;
}
