.span {
  text-align: center;
}

.container {
  height: auto;
}

.center {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.21rem;

}
