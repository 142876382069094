.spincontainer {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}



@keyframes spin {
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }

    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }

    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
}

.spinner {
    transform-origin: center;
    animation: spin 1s;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
