/* CSS */
.container {
    height: auto;
    margin: auto 1rem;
}


.center {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 1rem;
}

.button {
    appearance: button;
    background-color: #cc0f66;
    border: solid transparent #950849;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: din-round, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 70%;
    /* max-width: 50%; */
}

.button:after {
    background-clip: padding-box;
    background-color: #cc0f66;
    border: solid transparent;
    border-radius: 1rem;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.button:main,
.button:focus {
    user-select: auto;
}

.button:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
}

.button:disabled {
    cursor: auto;
}
