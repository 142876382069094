.container {
    height: auto;
}

.signaturecontainer {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
